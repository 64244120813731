










































































import {Component, Vue} from 'vue-property-decorator';
import {BrandDTO, CategoryDTO, UserProductDTO} from '@/generated';
import ProductModule from '@/store/modules/ProductModule';
import {getModule} from 'vuex-module-decorators';
import {Routes} from '@/router/Routes';
import {clone} from 'lodash';

@Component
export default class SingleProduct extends Vue {
    private readonly productsModule = getModule(ProductModule, this.$store);

    readonly routes = Routes;

    product!: UserProductDTO;
    category?: CategoryDTO;
    brand?: BrandDTO;

    editMode = false;
    editProduct!: UserProductDTO;

    created() {
        const product = this.productsModule.products.find(product => product.productId.toString() === this.$route.params.id.toString());
        if (!product) {
            this.$router.replace({name: Routes.NOT_FOUND});
            return;
        }
        this.product = product;
        if (product.categoryId) {
            this.category = this.productsModule.categories.find(category => category.categoryId === product.categoryId);
        }
        if (product.brandId) {
            this.brand = this.productsModule.brands.find(brand => brand.brandId === product.brandId);
        }
        this.editProduct = clone(product);
    }

    get imagePath(): string {
        return require(`@/assets/img/products/${this.product.imageName}`);
    }

    get categories(): CategoryDTO[] {
        return this.productsModule.categories;
    }

    get brands(): BrandDTO[] {
        return this.productsModule.brands;
    }

    resetProduct() {
        this.editProduct = clone(this.product);
        this.editMode = false;
    }
}
